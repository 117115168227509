import React from "react";


import { TopNav } from "../components/topNav";
import { AllFAQSection } from "../pageSections/allFAQSection";
import { Footer } from "../components/footer"


export const FAQ = () => {

  

    return (

        <>
            <TopNav />
          
            <AllFAQSection />
            <Footer />
          
        </>




    )


}