import React from "react";
import zombie from "../assets/courseImages/zombieB.png";
import minecraft from "../assets/courseImages/mineB.png";
import "../css/homePageMineRoblox.css"; // Importing the CSS file

export const HomePageMineRoblox = () => {
  return (
    <section id="Courses" className="mine-roblox">
      <div className="center-content">
        
          <button className="main-button">
            <span className="button-text">
              Minecraft/Roblox
            </span>
          </button>
      
        <h1 className="main-title">
          Code Gaming Adventures
        </h1>
        <h2 className="sub-title">
          Roblox Zombie Survival & Minecraft Mod Development for Kids
        </h2>
        <p className="description">
          Embark on coding adventures with our courses: survive a Roblox zombie apocalypse or create Minecraft mods, combining fun gaming with valuable programming skills for kids.
        </p>

        {/* Cards */}
        <div className="roblox-minecraft-card-container">
          {/* Roblox Card */}
          <div className="roblox-minecraft-card roblox-card">
          <img src={zombie} alt="Roblox Course" className="roblox-minecraft-card-image roblox-image" />
            <div className="roblox-minecraft-card-content">
              <div className="roblox-minecraft-card-header roblox">
                <p className="roblox-title">Roblox</p>
              
              </div>
              <div className="roblox-minecraft-card-buttons">
                <button className="secondary-button">Creativity</button>
                <button className="secondary-button">Lua</button>
                <button className="secondary-button">Critical Thinking</button>
              </div>
              <p className="roblox-text">Zombies are taking over Roblox! Can you survive the apocalypse?</p>
              <p className="course-description">
                In this exciting course, you'll learn how to code your own Roblox games.
                You'll create your own zombie apocalypse game, where you'll use coding skills to:
              </p>
              <ul className="list roblox">
                <li>build a base</li>
                <li>craft weapons</li>
                <li>and fight off hordes of zombies</li>
              </ul>
              <p className="final-note">Perfect for kids who love Roblox and want to learn coding.</p>
            <a href="https://calendly.com/code-n-game/intro" className="enquire-link roblox">
              Enquire now
            </a>
            </div>
           
          </div>

          {/* Minecraft Card */}
          <div className="roblox-minecraft-card minecraft-card">
            <img src={minecraft} alt="Minecraft Course" className="roblox-minecraft-card-image minecraft-image" />
            <div className="roblox-minecraft-card-content">
              <div className="roblox-minecraft-card-header minecraft">
                <p className="minecraft-title">Minecraft</p>
               
              </div>
              <div className="roblox-minecraft-card-buttons">
                <button className="secondary-button">World-Building</button>
                <button className="secondary-button">Java</button>
                <button className="secondary-button">Problem Solving</button>
              </div>
              <p className="minecraft-text">Do you love Minecraft? Do you want to learn how to code your own Minecraft mods and plugins?</p>
              <p className="course-description">
                In this course, you'll learn the basics of programming using Java, the programming language that powers Minecraft.
                You'll create your own Minecraft mods, where you'll use coding skills to:
              </p>
              <ul className="list minecraft">
                <li>add new blocks</li>
                <li>add items and mobs</li>
                <li>create own Minecraft plugins</li>
              </ul>
              <p className="final-note">Turn your child's gaming time into learning time!</p>
            <a href="https://calendly.com/code-n-game/intro" className="enquire-link minecraft">
              Enquire now
            </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
