import React, { useEffect, useRef } from 'react';
import '../css/introModal.css';

const onBuy = () => window.open('https://calendly.com/code-n-game/intro', '_blank');

export const IntroModal = ({ isOpen, onClose, videoUrl, buttonText }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div ref={modalRef} className="modalContainer">
                <div className="modalContent">
                    <div className="videoContent">
                        <iframe
                            className="videoIframe"
                            src={videoUrl}
                            title="Video Title"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>

                    </div>
                    <button
                        onClick={onBuy}
                        className="modalButton py-2 px-6 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-lg transition duration-150 ease-in-out hover:bg-gradient-to-r hover:from-purple-600 hover:to-blue-600 hover:scale-105"
                    >
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
};
