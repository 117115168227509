import React, { useState, useEffect, useRef } from 'react';
import '../css/homePageTop.css';

export const HomePageTopSlides = () => {
    const slideData = {
        "1": {
            "header": "Master Software Engineering Skills",
            "text": "Our program teaches software engineering in a unique and engaging way—through the development of games! Students will learn coding, debugging, and software design principles while creating their own games. This hands-on experience not only makes learning fun but also builds a solid foundation in software engineering. Equip your child with the skills needed to excel in the tech industry and beyond.",
            "gif": `${process.env.PUBLIC_URL}/staticImages/gameDev.gif`,
            "gifAlt": "alt1"
        },
        "2": {
            "header": "Unleash the Potential of AI",
            "text": "In our program, students will dive into the fascinating world of artificial intelligence. Through game-based learning, they will explore machine learning algorithms, neural networks, and AI-driven applications. Our innovative approach makes complex concepts easy to understand and exciting to learn. Watch your child transform into a tech-savvy innovator, ready to take on the future with cutting-edge AI skills.",
            "gif": `${process.env.PUBLIC_URL}/staticImages/AI.gif`,
            "gifAlt": "alt2"
        },
        "3": {
            "header": "Discover the Power of Data Science",
            "text": "Our program introduces students to the exciting world of data science through interactive and fun games. By engaging in hands-on projects, students will learn how to analyze data, identify patterns, and make data-driven decisions. They'll develop critical thinking and problem-solving skills that are essential in today's data-driven world. Empower your child with the knowledge of data science and set them on a path to success!",
            "gif": `${process.env.PUBLIC_URL}/staticImages/dataScience.gif`,
            "gifAlt": "alt13"
        }
    };

    const originalSlides = Object.values(slideData);
    const slides = [...originalSlides, {...originalSlides[0]}]; // Duplicate the first slide at the end
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(true);
    const slideInterval = useRef();

    const nextSlide = () => {
        setIsTransitioning(true);
        setCurrentSlide(prev => (prev + 1) % slides.length);
    };

    useEffect(() => {
        slideInterval.current = setInterval(nextSlide, 6000);
        return () => clearInterval(slideInterval.current);
    });

    useEffect(() => {
        if (currentSlide === slides.length - 1) { // When it's the duplicate slide
            setTimeout(() => {
                setIsTransitioning(false); // Disable transition
                setCurrentSlide(0); // Reset to actual first slide
            }, 1000); // Wait for the transition to complete
        }
    });

    const containerStyles = {
        transform: `translateX(-${currentSlide * 100}%) `,
        transition: isTransitioning ? 'transform 1s ease-in-out' : 'none'
    };

    return (
        <div className="slider-top-container">
            <div className="slide-container" style={containerStyles}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="text-container">
                            <h2>{slide.header}</h2>
                            <p>{slide.text}</p>
                        </div>
                        <div className='gif-container'>
                            <img src={slide.gif} alt={slide.gifAlt} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="dots">
                {originalSlides.map((_, idx) => (
                    <span key={idx} className={`dot ${currentSlide === idx ? 'active' : ''}`} onClick={() => {
                        setIsTransitioning(idx >= currentSlide);
                        setCurrentSlide(idx);
                    }}></span>
                ))}
            </div>
        </div>
    );
};
