import "../css/homePageBottom.css"

export const HomePageBottom = () => {
    return (
      <div>
        
  
        <div name="joinus" className="joinus-container">
          <div className="center-content">
            <div className="title-container">
              <p className="title-text">
                Start <span id="text" className="highlight-text">Coding</span> Your Adventure
              </p>
  
              <div className="mobile-button-container">
                <div className="button-background"></div>
                <a href="https://calendly.com/code-n-game/intro" title="Schedule Free Consultation"
                   className="button-link">Schedule Free Consultation
                </a>
              </div>
  
              <div className="laptop-button-container">
                <div className="laptop-button-background"></div>
                <a href="https://calendly.com/code-n-game/intro" title="Schedule Free Consultation"
                   className="laptop-button-link">Schedule Free Consultation
                </a>
              </div>
  
            </div>
          </div>
        </div>
      
      </div>
    );
  };
  