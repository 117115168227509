import '../css/card.css';

import React, {useState} from "react"


import CurriculumModal from './curriculumModal';
import curriculumData from "../curriculumData.json"

const Card = ({ title, imageSrc, description}) => {
    const [currentCourseCurriculum, setCurrentCourseCurriculum] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const openModalWithCurriculum = (curriculum) => {
        setCurrentCourseCurriculum(curriculum);
        setIsModalOpen(true);
    };
    

   const onViewCurriculum=() => openModalWithCurriculum(curriculumData.find(curriculum => curriculum.title === title))
   const onBuyNow=() => window.open('https://calendly.com/code-n-game/intro', '_blank')
    return (
        <div className="card-container">
            <div className="card">
                <article className="card-article">
                    <div className="card-image">
                        <img src={imageSrc} alt={title} />
                    </div>
                    <div className="card-content">
                        <div className="card-title">
                            <h3>{title}</h3>
                        </div>
                        <p className="card-description">
                            {description}
                        </p>
                        <div className="card-buttons">
                            <button className="btn-curriculum" onClick={onViewCurriculum}>
                                View Curriculum
                            </button>
                            <button className="btn-book" onClick={onBuyNow}>
                                Book Now
                            </button>
                        </div>
                    </div>
                </article>
            </div>
            <CurriculumModal
          isOpen={isModalOpen}
          curriculum={currentCourseCurriculum}
          onClose={() => setIsModalOpen(false)}
        />
        </div>
    );
};

export default Card;
