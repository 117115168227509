import React , {useState, useEffect} from 'react';
import { HomePageTopSlides } from '../components/homePageTopSlides';

export const HomePageTop = () => {

   
    const words = ["Coding", "Design", "Engineering", "Technology"]; // Add more words as needed
    const [index, setIndex] = useState(0);
  
    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % words.length); // Loop through words
        }, 2000); // Change word every 2 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, [words.length]);

    return (
        <div id="Home" className="home-top-container">
            <h1 className="top-h1-text">
    <div className="typewriter-wrapper">
    <div className="first-line">
        <span data-text="Ignite " className="ignite">Ignite </span>
        <span data-text="Your " className=" plain-header">Your </span>
        <span data-text="Passion " className="passion firy-text ">Passion </span>
        <span data-text="For " className=" plain-header">For </span>
        </div>
         <div className="second-line">
        <span data-text={words[index]} className=" flip-word">{words[index]}</span>
        </div>
    </div>
</h1>

            <HomePageTopSlides />

 
            <div className="button-container">
  <a href="https://calendly.com/code-n-game/intro" title="Schedule Free Trial" className="trial-button">
    Schedule Free Trial
  </a>
</div>


        </div>
    );
};
