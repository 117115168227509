
import "../css/refundPolicySection.css";

export const RefundPolicySection = () => {


  return (
    <section name="refund-policy">
   
      <div className="refundpolicy-section">
        <div className="refundpolicy-header">
          <h1 className="refundpolicy-title">Refund Policy</h1>
        </div>
        <div className="refundpolicy-content">
          <h2 className="refundpolicy-section-title">Free Trial Lesson</h2>
          <p className="refundpolicy-section-text">
            Trial Class Offer: We offer all new students a one-time, free trial lesson. This allows you to experience our teaching methods and the quality of our courses without any financial commitment.
          </p>
          <h2 className="refundpolicy-section-title">Payment and No Refund Policy</h2>
          <p className="refundpolicy-section-text">
            Enrollment and Payment: After the free trial, if you choose to enroll in our courses, payment must be made as per the fee structure provided.
            <br />
            No Refund Clause: Once payment is made for our courses, we do not offer refunds. This policy is in place due to our commitment to compensate our teachers for their time and to cover other non-recoverable costs. Keep in mind lessons will expire after six months if the students do not use them. This is also non-refundable.
          </p>
          <h2 className="refundpolicy-section-title">Missed Classes and Cancellation Policy</h2>
          <p className="refundpolicy-section-text">
            24-Hour Cancellation Notice: If you cannot attend a scheduled class, we require a 24-hour notice. If you cancel at least 24 hours before your scheduled class, we will offer you the opportunity to reschedule.
            <br />
            No Refunds for Missed Classes: If you miss a class without providing a 24-hour notice, no refund will be issued for that class. We encourage all students to adhere to this policy to ensure the smooth operation of our courses and fair compensation for our teachers.
          </p>
          <h2 className="refundpolicy-section-title">Exceptional Circumstances</h2>
          <p className="refundpolicy-section-text">
            Consideration for Exceptions: We understand that life can be unpredictable. In exceptional circumstances, such as severe illness or family emergencies, we may consider partial refunds or credits for future classes. These decisions are made at the discretion of Code & Game management and are considered on a case-by-case basis.
          </p>
          <h2 className="refundpolicy-section-title">Policy Communication</h2>
          <p className="refundpolicy-section-text">
            Acknowledgment of Policy: By enrolling in our courses and making a payment, you acknowledge that you have read, understood, and agreed to our refund policy.
            <br />
            Contact Us for Concerns: If you have any questions or concerns about our refund policy, please contact us at <span className="refundpolicy-contact-link"><a href="mailto:support@codegames.tech">support@codegames.tech</a></span>. We are committed to ensuring a positive and fair experience for all our students.
          </p>
        </div>
      </div>
    
    </section>
  );
};
