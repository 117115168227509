import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/topNav.css";
import { IntroModal } from "./introModal";
import { TopPromo } from "./topPromo";

export const TopNav = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [promoHeight, setPromoHeight] = useState(0);
  const promoRef = useRef(null);
  const navigate = useNavigate();
  const [wholeNavHeight, setWholeNavHeight] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const wholeNavRef = useRef(null);
  const navBarRef = useRef(null);

  useEffect(() => {
    const wholeNav = wholeNavRef.current;
    const navBar = navBarRef.current;

    if (wholeNav && navBar) {
      const updateHeight = () => {
        setWholeNavHeight(wholeNav.offsetHeight + navBar.offsetHeight);
      };

      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(wholeNav);
      resizeObserver.observe(navBar);

      // Initial height calculation
      updateHeight();

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.unobserve(wholeNav);
        resizeObserver.unobserve(navBar);
        resizeObserver.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
     
        setPaddingTop(wholeNavHeight);
      
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [wholeNavHeight]);

  useEffect(() => {
    const handleScroll = () => {
      if (promoRef.current) {
        promoRef.current.style.position = "fixed";
        promoRef.current.style.zIndex = "4";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [promoHeight]);

  const handleNavClick = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const handleNavItemClick = (link) => {
    const currentPath = window.location.pathname;

    const scrollToElement = async (link, delay = 1) => {

      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      await wait(delay);

      const targetElement = document.getElementById(link);
      
      if (targetElement) {
     
        const elementPosition = targetElement.offsetTop;
        let offsetPosition = elementPosition - wholeNavHeight;

        if (link === "Mission") {
          offsetPosition = offsetPosition - 40;
        }

        if (link === "Home") {
          offsetPosition = 0;
        }

        setTimeout(() => {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });

        
        }, delay); // Execute the scroll after the specified delay
      }
    };

    if (currentPath !== "/") {
      navigate(`/#${link}`);
      scrollToElement(link); // Delay to ensure navigation has completed
    } else {
      scrollToElement(link); // Delay to ensure DOM has updated
    }

    setIsNavExpanded(false);
  };

  const handleIntroClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleLogoClick = () => {
    const currentPath = window.location.pathname;

    if (currentPath === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div ref={promoRef}>
        <div ref={wholeNavRef} className="wholenav">
          <TopPromo setPromoHeight={setPromoHeight} />
          <nav
            ref={navBarRef}
            className="navbar"
            style={{
              top: `${promoHeight}px`,
            }}
          >
            <div
              className={`hamburger ${isNavExpanded ? "open" : ""}`}
              onClick={handleNavClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="nav-logo">
              <Link to="/" className="logo" onClick={handleLogoClick}>
                <p className="font-bold">
                  <span className="text-code">Code </span>
                  <span className="text-amp">&amp;</span>
                  <span className="text-game"> Game</span>
                </p>
              </Link>
            </div>

            <div className={`nav-links ${isNavExpanded ? "show" : ""}`}>
              <a href="#Home" className="nav-item" onClick={() => handleNavItemClick("Home")}>
                Home
              </a>
              <a href="#Mission" className="nav-item" onClick={() => handleNavItemClick("Mission")}>
                Mission
              </a>
              <a href="#Courses" className="nav-item" onClick={() => handleNavItemClick("Courses")}>
                Courses
              </a>
              <a href="#FAQ" className="nav-item" onClick={() => handleNavItemClick("FAQ")}>
                FAQ
              </a>
            </div>

            <div className="nav-buttons">
              <a className="intro-button" onClick={handleIntroClick}>
                Intro
              </a>
              <a href="https://calendly.com/code-n-game/intro" className="start-button">
                Start Now →
              </a>
            </div>
          </nav>
        </div>

        <IntroModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          videoUrl="https://player.vimeo.com/video/916610849?h=7f14cca984"
          buttonText="Claim A Free Session"
        />
      </div>
      <div style={{ paddingTop: `${paddingTop}px` }}></div>
    </>
  );
};

export default TopNav;
