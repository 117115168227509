
import "../css/privacyPolicySection.css";

export const PrivacyPolicySection = () => {


  return (
    <section name="privacy-policy">
  
      <div className="privacy-policy-section">
        <div className="privacy-policy-header">
          <h1 className="privacy-title">Privacy Policy</h1>
        </div>
        <div className="content">
          <h2 className="pp-section-title">Information We Collect</h2>
          <p className="pp-section-text">
            We may collect personal information including but not limited to:
            <ul className="pp-list-disc">
              <li>
                Name and Contact Data: Full name, parent/guardian name, email
                address, and phone number.
              </li>
              <li>
                Credentials: Passwords and other security information for
                authentication and access.
              </li>
              <li>
                User Content: Any content created by users within our platform
                (e.g., code written during courses).
              </li>
            </ul>
          </p>

          <h2 className="pp-section-title">How We Use Your Information</h2>
          <p className="pp-section-text">
            Your information may be used for the following purposes:
            <ul className="pp-list-disc">
              <li>To provide and manage our services.</li>
              <li>
                To communicate with you, including via email or telephone,
                regarding our services.
              </li>
              <li>
                To improve our website and services through customer feedback.
              </li>
            </ul>
          </p>

          <h2 className="pp-section-title">Sharing of Your Information</h2>
          <p className="pp-section-text">
            We do not sell your information. We may share information with
            third-party service providers to help us operate our business and
            the site or administer activities on our behalf, such as sending out
            emails, newsletters, or surveys.
          </p>

          <h2 className="pp-section-title">Data Retention</h2>
          <p className="pp-section-text">
            We retain personal information for as long as necessary to provide
            the services you have requested, and thereafter for a variety of
            legitimate legal or business purposes. These might include retention
            periods mandated by law, contract, or similar obligations applicable
            to our business operations; for preserving, resolving, defending, or
            enforcing our legal/contractual rights; or needed to maintain
            adequate and accurate business and financial records.
            <br />
            If you request, we will delete or anonymize your personal data so
            that it no longer identifies you, unless we are legally allowed or
            required to maintain certain personal data, including situations
            such as the following:
            <ul className="pp-list-disc">
              <li>
                If there is an unresolved issue relating to your account, such
                as an outstanding credit on your account or an unresolved claim
                or dispute, we will retain the necessary personal data until the
                issue is resolved.
              </li>
              <li>
                Where we are required to retain the personal data for our legal,
                tax, audit, and accounting obligations, we will retain the
                necessary personal data for the period required by applicable
                law.
              </li>
              <li>
                Where necessary for our legitimate business interests such as
                fraud prevention or to maintain the security of our users.
              </li>
            </ul>
          </p>

          <h2 className="pp-section-title">Security of Your Information</h2>
          <p className="pp-section-text">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>

          <h2 className="pp-section-title">Your Rights</h2>
          <p className="pp-section-text">
            You have the right to:
            <ul className="pp-list-disc">
              <li>Request access to your personal data.</li>
              <li>
                Request correction of the personal data that we hold about you.
              </li>
              <li>Request erasure of your personal data.</li>
              <li>Object to processing of your personal data.</li>
              <li>
                Request the transfer of your personal data to you or to a third
                party.
              </li>
            </ul>
          </p>

          <h2 className="pp-section-title">Policy for Children</h2>
          <p className="pp-section-text">
            Parental Consent: In compliance with the Children's Online Privacy
            Protection Act (COPPA), we require verifiable parental consent
            before collecting personal information from children under 13. This
            consent will be obtained in a manner that is reasonably designed to
            ensure that the person providing consent is the child’s parent or
            legal guardian.
            <br />
            Information Collected: For children under 13, we only collect
            personal information that is necessary for participating in our
            online coding courses. This may include the child’s name, contact
            information (email address), and other relevant details for course
            enrollment and communication.
            <br />
            Use of Information: The personal information collected will be used
            exclusively for the purpose of providing our educational services.
            This includes course enrollment, instruction, and maintaining
            communication with the child and their parents or guardians.
          </p>

          <p className="pp-section-text">
            Disclosure of Information: We do not disclose personal information
            collected from children under 13 to third parties unless it is
            necessary for the provision of our services or as required by law.
            <br />
            Parental Rights: Parents or guardians have the right to review the
            personal information collected from their child, request deletion,
            and refuse to allow any further collection or use of the child’s
            information. Parents can exercise these rights by contacting us at
            the details provided below.
          </p>

          <h2 className="pp-section-title">Changes to This Privacy Policy</h2>
          <p className="pp-section-text">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h2 className="pp-section-title">Contact Us</h2>
          <p className="pp-section-text">
            If you have questions or comments about this Privacy Policy, please
            contact us at:{" "}
            <span className="contact-link">
              <a href="mailto:support@codegames.tech">support@codegames.tech</a>
            </span>
          </p>
        </div>
      </div>
  
    </section>
    
  );
};
