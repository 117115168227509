
import "../css/termsSection.css";

export const TermsSection = () => {


  return (
    <section name="terms-and-conditions">
     
      <div className="terms-section">
        <div className="terms-header">
          <h1 className="terms-title">Terms & Conditions</h1>
        </div>
        <div className="terms-content">
          <h2 className="terms-section-title">Introduction</h2>
          <p className="terms-section-text">
            Welcome to Code & Game, a dynamic online platform offering a range
            of coding courses designed for a diverse group of learners. Our
            services include personalized and standard coding lessons,
            project-based learning experiences, and tailored courses to meet
            individual learning objectives.
          </p>

          <h2 className="terms-section-title">Definitions Section</h2>
          <p className="terms-section-text">
            In these Terms and Conditions, the following terms shall have the
            meanings set out below:
            <ul className="terms-list-disc">
              <li>
                'User': Any individual who accesses and uses our services.
              </li>
              <li>
                'Services': The online coding lessons, course materials, and
                other educational content provided by Code & Game.
              </li>
              <li>
                'Content': All material, including but not limited to text,
                images, video, and audio, provided as part of the Services.
              </li>
              <li>
                'Course Material': Specific educational content, tools, and
                resources provided for each course offered by Code & Game.
              </li>
            </ul>
          </p>

          <h2 className="terms-section-title">Course Enrollment and Fees</h2>
          <p className="terms-section-text">
            Courses are paid for upfront. The first lesson for each student is
            provided free of charge, allowing students and parents to evaluate
            our services. You are free to choose to not pay for the services
            after the first lesson. Once the fee for a course is paid after the
            first lesson, there are no refunds available. This policy
            underscores our commitment to the quality and value of our courses.
            For more detailed information on our refund policy, see our refund
            policy page.
            <br />
            Also, please note that unused lessons will expire after six months.
            There is no refund for expired lessons.
          </p>

          <h2 className="terms-section-title">User Responsibilities</h2>
          <p className="terms-section-text">
            <strong>Account Integrity:</strong> Users are responsible for
            maintaining the confidentiality of their account information and for
            all activities under their account.
            <br />
            <strong>Acceptable Use:</strong> Users must not misuse our services,
            including any unauthorized copying or distribution of course
            materials or disrupting the learning environment.
            <br />
            <strong>Age Requirement:</strong> Users under the age of 18 must
            have consent from a parent or guardian who agrees to these terms.
          </p>

          <h2 className="terms-section-title">Privacy Policy</h2>
          <p className="terms-section-text">
            In compliance with the California Consumer Privacy Act (CCPA) and
            other data protection laws, we collect minimal personal information,
            such as names, goals, and contact details (email/phone), solely for
            the purpose of providing and improving our services. We are
            committed to protecting your privacy and will not share your
            personal information with third parties without your consent, except
            as required by law. Minor's Privacy: "We take the privacy of minors
            very seriously. In compliance with the Children's Online Privacy
            Protection Act (COPPA) and other relevant legislation, the
            collection and use of personal information from minors is conducted
            with the utmost care and only for the purposes of delivering our
            educational services." For more detailed information on privacy
            policy, see the full privacy policy on our privacy policy page.
          </p>

          <h2 className="terms-section-title">Intellectual Property</h2>
          <p className="terms-section-text">
            All course materials, content, and teaching methods provided by Code
            & Game are the intellectual property of Code & Game or its
            licensors. While users may use these materials for personal
            educational purposes, any redistribution or commercial use is
            strictly prohibited. User-generated content, such as project
            submissions, remains the intellectual property of the user, though
            Code & Game may use such content for promotional purposes.
            <br />
            User-Generated Content: "Users retain ownership of any original
            content they create as part of our courses, including projects.
            However, by participating in our courses, users grant Code & Game
            the right to use this content for promotional purposes, such as
            showcasing student achievements. This use is solely to highlight the
            effectiveness of our courses and the talents of our students."
          </p>

          <h2 className="terms-section-title">Limitation of Liability</h2>
          <p className="terms-section-text">
            Code & Game is not liable for any indirect, incidental, special,
            consequential or punitive damages, including loss of profits, data,
            or other intangible losses, resulting from your use of our services.
          </p>

          <h2 className="terms-section-title">Dispute Resolution</h2>
          <p className="terms-section-text">
            "Any disputes arising out of or related to these Terms and
            Conditions or the use of our services shall be resolved through
            binding arbitration. By agreeing to these terms, users waive the
            right to a trial by jury or to participate in a class action. Code &
            Game is indemnified to the fullest extent permitted by law in such
            matters."
          </p>

          <h2 className="terms-section-title">Feedback and Complaints</h2>
          <p className="terms-section-text">
            "We welcome feedback and are committed to resolving complaints
            effectively. Users can formally lodge a complaint or provide
            feedback by emailing support@codegames.tech. We strive to address
            all concerns promptly and constructively."
          </p>

          <h2 className="terms-section-title">Links to Third-Party Websites</h2>
          <p className="terms-section-text">
            "Our Services may include links to third-party websites as part of
            the course materials. These websites are not under our control, and
            we are not responsible for their content. Users access these
            websites at their own risk, and we encourage users to review the
            terms and privacy policies of these third-party sites."
          </p>

          <h2 className="terms-section-title">Updates to Terms and Conditions</h2>
          <p className="terms-section-text">
            "These Terms and Conditions may be updated periodically. Continued
            use of our website and services after any such changes constitutes
            your consent to such changes. We recommend that users review the
            Terms and Conditions regularly to stay informed of any updates."
          </p>

          <h2 className="terms-section-title">Warranty Disclaimer</h2>
          <p className="terms-section-text">
            Our services are provided "as is" and "as available" without any
            warranties, express or implied, including but not limited to the
            implied warranties of merchantability, fitness for a particular
            purpose, or non-infringement.
          </p>

          <h2 className="terms-section-title">Termination of Use</h2>
          <p className="terms-section-text">
            Code & Game reserves the right to terminate or suspend access to our
            services for any user who violates these terms or engages in
            activities harmful to our platform, other users, or the learning
            environment.
          </p>

          <h2 className="terms-section-title">Governing Law</h2>
          <p className="terms-section-text">
            These Terms and Conditions shall be governed and construed in
            accordance with the laws of the State of California, United States,
            without regard to its conflict of law provisions.
          </p>

          <h2 className="terms-section-title">Amendments</h2>
          <p className="terms-section-text">
            We reserve the right to modify or replace these Terms at any time.
          </p>

          <h2 className="terms-section-title">Contact Information</h2>
          <p className="terms-section-text">
            For any questions or concerns regarding these Terms and Conditions,
            please contact us at:{" "}
            <span className="terms-contact-link"><a href="mailto:support@codegames.tech">support@codegames.tech</a></span>
          </p>

          <p className="terms-footer-text">
            At Code & Game, we are deeply committed to providing a
            high-quality, engaging, and accessible coding education for our
            learners. Our dedication extends beyond just delivering
            comprehensive course content; it encompasses a holistic approach to
            support, nurture, and inspire our students. We believe in building a
            community where innovation, creativity, and learning thrive. As we
            continue to grow and evolve, our focus remains steadfast on
            upholding the highest standards of service and integrity. We
            appreciate the trust you place in us as your educational partner and
            are always here to assist, listen, and improve. Thank you for
            choosing Code & Game as your gateway to the exciting world of coding
            and technology.
          </p>
        </div>
      </div>
   
    </section>
  );
};
