import React from 'react';
import '../css/homePageMiddle.css';

import call from "../assets/images/call.png";
import discuss from "../assets/images/discuss.jpg"
import test from "../assets/images/test.png"
import start from "../assets/images/start.png"

export const HomePageMiddle = () => {

  const gifRight = `${process.env.PUBLIC_URL}/staticImages/mission.gif`;
  const gifLeft = `${process.env.PUBLIC_URL}/staticImages/best.gif`;



  const steps = [
    {
      text: 'Book A Call',
      img: call
    },
    {
      text: 'Discuss Student’s Passions & Goals',
      img: discuss
    },
    {
      text: 'Take A Quick Skills Assessment',
      img: test
    },
    {
      text: 'Personalize Curriculum & Start!',
      img: start
    }
  ];

  const guarantees = [
    {
      mainText: 'No Experience Required!',
      subText: '(We have curriculum that can be tailored for any level.)'
    },
    {
      mainText: 'No Commitment Payment Plans.',
      subText: '(Leave anytime, no monthly subscriptions.)'
    },
    {
      mainText: 'Try for free!',
      subText: '(No Credit Card required.)'
    },
    {
      mainText: 'Flexible Scheduling & Cancellations.',
      subText: '(We will always work at your convenience with 24-hour rescheduling.)'
    },
    {
      mainText: 'Fun Content.',
      subText: '(Our courses are designed with fun games kids love!)'
    },
    {
      mainText: 'Engaging Classes.',
      subText: '(Our tutors are trained on how to engage kids - no boring lectures.)'
    },
    {
      mainText: 'Build Critical Thinking Skills.',
      subText: '(The focus in our curriculum is to get kids to think deeply and foster creativity.)'
    },
    {
      mainText: 'Personalized Curriculum.',
      subText: '(Every student gets their own custom made learning plan.)'
    },
    {
      mainText: 'Progress Tracking.',
      subText: '(While students are busy having fun, we track and report back their progress.)'
    }
  ];

  return (


    <div className="homepage-middle">

      <section className="what-to-expect">
        <div className="process">
          <h2>The Process</h2>
          <div className="process-steps">
            {steps.map((step, index) => (
              <React.Fragment key={`step-${index}`}>
                <div className="step">
                  <img src={step.img} alt={`step number ${index}`} />
                  <span className="step-text">{step.text}</span>
                </div>
                {index < steps.length - 1 && <div className="arrow">&rarr;</div>}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="guarantees">
          <h2>Our Guarantees</h2>
          <div className="guarantee-items">
            {guarantees.map((guarantee, index) => (
              <div className="guarantee" key={`guarantee-${index}`}>
                <div className="main-text">{guarantee.mainText}</div>
                <div className="sub-text">{guarantee.subText}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <button id="Mission" className="mission__btn">
        <span className="mission__btn-text">Our Mission</span>
      </button>

      <section className="fit-for-you">
        <h2>What Is Our Mission?</h2>
        <div className="content-container"> {/* New container for side-by-side layout */}
          <div className="text-content">
            <p>

              Our mission is to empower the youth with the necessary tools and knowledge so that they
              can flourish in the futuristic world of technology. We specialize in teaching gaming
              enthusiasts interested in creating mods for their games, students who appreciate the
              flexibility of online tutoring tailored to their schedules, and kids who flourish
              in a dynamic, enjoyable, and interactive learning environment. We also
              cater to learners seeking to acquire coding and tech skills not typically
              covered in standard school curriculums, offering personalized attention
              and customized learning plans.</p>
          </div>
          <div className="gif-container">
            <img src={gifRight} alt="Right Aligned GIF" />
          </div>
        </div>
      </section>

      <section className="what-makes-us-best">
        <h2>What Makes Us The Best?</h2>
        <div className="content-container"> {/* New container for side-by-side layout */}
          <div className="gif-container">
            <img src={gifLeft} alt="Left Aligned GIF" />
          </div>
          <div className="text-content">
            <p>We are the best because we use proven study techniques like spaced repitiion
              and the pomodoro technique, along with utilizing a systematic structure for
              reviewing previously learned concepts that maximize retention and understanding. The
              unique approach to teach kids through gaming to keep them engaged is complemented by
              our carefully crafted curriculum that establishes a solid foundation in programming.
              We keep students actively engaged by presenting them with thought-provoking challenges
              to sharpen their critical thinking skills. This type of collaborative learning
              environment tailored to each individual's ability and interests fosters effective
              communication as individualized feedback and support is provided to optimize
              their learning experience.</p>

          </div>
        </div>
      </section>

    </div>
  );
}
