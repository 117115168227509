import React from "react";


import { TopNav } from "../components/topNav";
import { PrivacyPolicySection } from "../pageSections/privacyPolicySection";
import { Footer } from "../components/footer"


export const PrivacyPolicy = () => {

  

    return (

        <>
            <TopNav />
           
          <PrivacyPolicySection />
            <Footer />
           
        </>




    )


}