import React from "react";
import customImage from "../assets/courseImages/customA.png";
import "../css/homePageOtherCards.css"; // Update the path to where your CSS file is located

export const HomePageOtherCards = () => {
  return (
    <div className="custom-games">
      <p className="custom-games__btn-container">
        <button className="custom-games__btn">
          <span className="custom-games__btn-text">custom games</span>
        </button>
      </p>

      <h1 className="custom-games__title">
        Advanced Programming
      </h1>

       {/* Image container */}
       <div className="custom-games__image-container">
        <img src={customImage} alt="Custom game" />
      </div>

      <div className="custom-games__description-container">
        <p className="custom-games__description">
          Experience an immersive journey into advanced game development programming, where experienced programmers create personalized game projects, mastering cutting-edge technologies and gaining hands-on expertise for a creative future.
        </p>
      </div>

      {/* Cards container */}
      <div className="custom-games__cards-container">
        {/* Coding Sessions Card */}
        <div className="custom-games__card card-one">
          <div className="custom-games__card-icon">
            {/* SVG Icon */}
          </div>
          <div className="custom-games__card-body">
            <h5 className="custom-games__card-title">Coding Sessions</h5>
            <p className="custom-games__card-sessions">4 sessions</p>
            <p className="custom-games__card-text">
              Dive into a thrilling gaming session where students can choose their favorite video game and uncover the magic behind the screen! This isn't your average playtime; it's a dynamic, hands-on discovery of what makes each game tick.
            </p>
          </div>
          <div className="custom-games__card-actions">
            <a className="custom-games__card-link" href="https://calendly.com/code-n-game/intro">
              Enquire now
              {/* SVG Arrow */}
            </a>
          </div>
        </div>

        {/* Custom Course Card */}
        <div className="custom-games__card card-two">
          <div className="custom-games__card-icon">
            {/* SVG Icon */}
          </div>
          <div className="custom-games__card-body">
            <h5 className="custom-games__card-title">Custom Course</h5>
            <p className="custom-games__card-sessions">Advanced</p>
            <p className="custom-games__card-text">
              Are you a programmer with a passion for game development? Do you want to create your own game but need guidance and support? This course is for you! In this course, you will work with an experienced developer to create your own custom course curriculum, tailored to your specific goals and interests.
            </p>
          </div>
          <div className="custom-games__card-actions">
            <span className="custom-games__card-pricing">Custom Pricing</span>
            <a className="custom-games__card-link" href="https://calendly.com/code-n-game/intro">
              Enquire now
              {/* SVG Arrow */}
            </a>
          </div>
        </div>
      </div>

     
    </div>
  );
};
