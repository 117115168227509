import React, { useEffect, useRef } from 'react';
import "../css/topPromo.css";

export const TopPromo = ({ setPromoHeight }) => {
    const promoRef = useRef(null);

    useEffect(() => {
        const updateHeight = () => {
            if (promoRef.current) {
                const height = promoRef.current.getBoundingClientRect().height;
                
                setPromoHeight(height);
            }
        };

        // Call immediately and on resize
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, [setPromoHeight]);

    return (
        <div ref={promoRef} className="nav-container">
            
                <div className="promoBanner">
                    <p className="contactInfo">
                        <span className="phone">
                            <a href="tel:+18054222507">+1 (805) 422-2507</a>
                        </span>
                        <span className="separator">|</span>
                        <span className="email">
                            <a href="mailto:support@codegames.tech">support@codegames.tech</a>
                        </span>
                    </p>
                    <p className="promoText">
                        Contact us to enquire about the summer 2024 bootcamps. Enrollment only open while spots remain.
                    </p>
                </div>
            
        </div>
    );
};
