import React, { useState, useRef, useEffect } from 'react';
import Card from './card'; 

import "../css/cardSlider.css"


export const CardSlider = ({ data, cardImages }) => {
    const scrollContainer = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(1);

    const calculateCardsToShow = (width) => {
        if (width >= 1500) return 3;
        else if (width >= 1000) return 2;
        return 1;
    };

    const [cardsToShow, setCardsToShow] = useState(calculateCardsToShow(window.innerWidth));

    useEffect(() => {
        const handleResize = () => {
            const vw = window.innerWidth;
            const newCardsToShow = calculateCardsToShow(vw);
            if (newCardsToShow !== cardsToShow) {
                setCardsToShow(newCardsToShow);
            }
            scrollToCurrentIndex(currentIndex);  // Recalculate positions
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [currentIndex, cardsToShow]);

    const scrollToCurrentIndex = (index) => {
        if (scrollContainer.current && scrollContainer.current.firstChild) {
            const cardWidth = scrollContainer.current.firstChild.offsetWidth;
            let scrollPosition = (index - 1) * cardWidth;
    
            scrollContainer.current.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        } else {
            console.error('Scroll container or first child not available.');
        }
    };
    

    const scrollCards = (direction) => {
        let newIndex = currentIndex + direction;
        if (newIndex < 1) newIndex = data.length;
        else if (newIndex > data.length) newIndex = 1;
        setCurrentIndex(newIndex);
        scrollToCurrentIndex(newIndex);
    };

    const displayCards = () => {
        let visibleCards = [];
        for (let i = 0; i < cardsToShow; i++) {
            let cardIndex = (currentIndex + i - 1 + data.length) % data.length;
            visibleCards.push(data[cardIndex]);
        }
        return visibleCards;
    };

    return (
        <div className="card-slider">
            {displayCards().map((course, index) => (
                <Card
                    key={index}
                    title={course.courseTitle}
                    imageSrc={cardImages[course.courseTitle]}
                    price={course.coursePrice}
                    description={course.courseDetails}
                />
            ))}
            <button onClick={() => scrollCards(-1)} className="course-arrow left">◀</button>
            <button onClick={() => scrollCards(1)} className="course-arrow right">▶</button>
        </div>
    );
};

