import React from "react";
import { Link } from "react-router-dom";

import "../css/footer.css"

export const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer">
 <hr className="custom-hr"></hr>

      <div className="footer-links-small">
        <div className="flex-column-center">
          <ul className="link-list-small">
            <li className="link-item">
              <Link to="/terms-and-conditions" onClick={handleLinkClick} className="link">
                Terms & Conditions
              </Link>
            </li>
            <li className="link-item">
              <Link to="/privacy-policy" onClick={handleLinkClick} className="link">
                Privacy Policy
              </Link>
            </li>
            <li className="link-item">
              <Link to="/refund-policy" onClick={handleLinkClick} className="link">
                Refund Policy
              </Link>
            </li>
            {/* <li className="link-item">
              <Link to="/careers" onClick={handleLinkClick} className="link">
                Careers
              </Link>
            </li> */}
          </ul>
          <div className="text-center">
            <span className="footer-text">
              © 2024 Code&Game™. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>

      <div className="footer-links-large">
        <div className="footer-links-inner">
          <ul className="link-list-large">
            <li className="link-item">
              <Link to="/terms-and-conditions" onClick={handleLinkClick} className="link">
                Terms & Conditions
              </Link>
            </li>
            <li className="link-item">
              <Link to="/privacy-policy" onClick={handleLinkClick} className="link">
                Privacy Policy
              </Link>
            </li>
            <li className="link-item">
              <Link to="/refund-policy" onClick={handleLinkClick} className="link">
                Refund Policy
              </Link>
            </li>
            {/* <li className="link-item">
              <Link to="/careers" onClick={handleLinkClick} className="link">
                Careers
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="text-center">
          <span className="footer-text">
            © 2024 Code&Game™. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
