import React from 'react';
import '../css/curriculumModal.css';

const CurriculumModal = ({ isOpen, curriculum, onClose }) => {
    if (!isOpen || !curriculum) return null;

    const handleBackdropClick = (event) => {
        if (event.currentTarget === event.target) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleBackdropClick}>
            <div className="modal-content">
                <div className="modal-header">
                    <h3>{curriculum.title}</h3>
                </div>
                <div className="modal-body">
                    <ol>
                        {curriculum.sections?.map((section, sectionIndex) => (
                            <li key={sectionIndex} className="section">
                                <h5>{section.heading}</h5>
                                <ol>
                                    {section.content.map((item, itemIndex) => (
                                        <li key={itemIndex}>{item}</li>
                                    ))}
                                </ol>
                            </li>
                        ))}
                    </ol>
                </div>
                <div className="modal-footer">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default CurriculumModal;
