import React from "react";


import { TopNav } from "../components/topNav";
import { TermsSection } from "../pageSections/termsSection";
import { Footer } from "../components/footer"


export const Terms = () => {

   
    return (

        <>
            <TopNav />
           
                <TermsSection />
                <Footer />
           
        </>




    )


}