import React from "react";


import { TopNav } from "../components/topNav";
import { RefundPolicySection } from "../pageSections/refundPolicySection";
import { Footer } from "../components/footer"


export const RefundPolicy = () => {

  

    return (

        <>
            <TopNav />
           
          <RefundPolicySection />
            <Footer />
          
        </>




    )


}