import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/homePageFAQ.css'; 
import allFaqs from "../faqs.json"



export const HomePageFaqs = () => {

  const [expandedId, setExpandedId] = useState(null);
  const navigate = useNavigate();

  const faqs= []

  for (let i = 0; i < 6; i++){
   
    faqs.push(allFaqs[i])
  }

  const toggleFAQ = (id) => {
      setExpandedId(expandedId === id ? null : id);
  };

  const navigateToFaqs = () => {
    navigate('/faq');
    window.scrollTo(0, 0);
  };

  return (
    <section id="FAQ" className="faq-section">
        <p className="faq-header">
          <button className="faq-button">
            <p className="faq-title">FAQ</p>
          </button>
        </p>
        <p className="faq-subtitle">Frequently Asked Questions</p>
        <p className="faq-intro">Your go-to resource for straightforward answers to commonly asked questions.</p>

        <div className="faq-content">
          {faqs.map(faq => (
            <div key={faq.id} className="faq-item">
                <h2 className="faq-question-header">
                    <button 
                        className="faq-question-button" 
                        aria-expanded={expandedId === faq.id} 
                        onClick={() => toggleFAQ(faq.id)}
                    >
                        <span className="question-text">{faq.question}</span>
                        <svg className="faq-icon" aria-hidden="true" viewBox="0 0 10 6">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
                        </svg>
                    </button>
                </h2>
                <div className={`faq-answer ${expandedId === faq.id ? 'show' : 'hide'}`}>
                    <div className="faq-answer-content">
                        <p className="homepage-faq-answers">{faq.answer}</p>
                    </div>
                </div>
            </div>
          ))}

          <p className="more-faqs">
            <button onClick={navigateToFaqs} className="more-faqs-button">
              More FAQs
            </button>
          </p>
        </div>
    </section>
  );
};
