import React from "react";



import { HomePageTop } from "../pageSections/homePageTop";
import { TopNav } from "../components/topNav";
import { HomePageMiddle } from "../pageSections/homePageMiddle";
import { HomePageMineRoblox } from "../pageSections/homePageMineRoblox";
import { HomePageCourses } from "../pageSections/homePageCourses";
import { HomePageOtherCards } from "../pageSections/homePageOtherCards";
import { HomePageFaqs } from "../pageSections/homePageFAQ";
import {Footer} from "../components/footer"
import { HomePageBottom } from "../pageSections/homePageBottom";


export const HomePage = () => {

 

    return (
        <>
    
          <TopNav />
     
             <HomePageTop />
            <HomePageMiddle /> 
            <HomePageMineRoblox />
             <HomePageCourses />
            <HomePageOtherCards />
            <HomePageFaqs />
            <HomePageBottom />
            <Footer />  
     
        </>
      );
    };