import React, { useState } from 'react';
import allFaqs from "../faqs.json";
import "../css/allFAQSection.css";  
export const AllFAQSection = () => {
    const [activeId, setActiveId] = useState(null);

    const toggleFAQ = (id) => {
        setActiveId(activeId === id ? null : id);
    };

    return (
        <section className='faqpage-more-faqs'>
            <div className='faqpage-content-wrapper'>
                <div className='faqpage-header'>
                    <h1 className='faqpage-header-title'>Frequently Asked Questions</h1>
                </div>
                <div className='faqpage-faq-list'>
                    {allFaqs.map(({ id, question, answer }) => (
                        <div key={id}>
                            <h2 id={`accordion-flush-heading-${id}`}>
                                <button
                                    type="button"
                                    className={`faqpage-accordion-header ${activeId === id ? 'faqpage-show' : 'faqpage-collapsed'}`}
                                    onClick={() => toggleFAQ(id)}
                                    aria-expanded={activeId === id}
                                    aria-controls={`accordion-flush-body-${id}`}
                                >
                                    <span className="faqpage-question-text">{question}</span>
                                    <svg
                                        className={`faqpage-icon ${activeId !== id && 'faqpage-rotate'}`}
                                     
                                        aria-hidden="true" 
                                        viewBox="0 0 10 6"
                                    >
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                    </svg>
                                </button>
                            </h2>
                            <div
                                id={`accordion-flush-body-${id}`}
                                className={`faqpage-accordion-body ${activeId === id ? 'faqpage-show' : 'faqpage-hide'}`}
                                aria-labelledby={`accordion-flush-heading-${id}`}
                            >
                                <div className="faqpage-accordion-content">
                                    <p className="faqpage-answer">{answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="faqpage-talk-link-wrapper">
                <a href="https://calendly.com/code-n-game/intro" target="_blank" rel="noopener noreferrer" className="faqpage-talk-link">
                    Have more questions? Let's talk.
                </a>
            </div>
        </section>
    );
};
