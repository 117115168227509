import { BrowserRouter, Routes, Route } from "react-router-dom"

import { HomePage } from "./pages/homePage";
import { FAQ } from "./pages/FAQ";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { RefundPolicy } from "./pages/refundPolicy";
import { Terms } from "./pages/terms";

function App() {
  return (

    <BrowserRouter>
      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />

        <Route path="/terms-and-conditions" element={<Terms />} />


      </Routes>



    </BrowserRouter>

  );
}

export default App;
