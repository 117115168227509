import React from 'react';
import {CardSlider} from '../components/cardSlider';
import courseData from '../courseInfo.json';

import "../css/homePageCourses.css"

// Image imports
import tictactoe from "../assets/courseImages/tic-tac-toe.png";
import snake from "../assets/courseImages/snake.png";
import airhockey from "../assets/courseImages/air-hockey.png";
import flappybird from "../assets/courseImages/flappy-bird.webp";
import monsterinvaders from "../assets/courseImages/monster-invaders.webp";

export const HomePageCourses = () => {
    const cardImages = {
        "Tic-Tac-Toe": tictactoe,
        "Snake Game": snake,
        "Flappy Bird": flappybird,
        "Air Hockey": airhockey,
        "Monster Invaders": monsterinvaders
    };

    return (
        <div className="code-games">
            <p className="code-games__btn-container">
                <button className="courses__btn">
                    <span className="courses__btn-text">Other Courses</span>
                </button>
            </p>
            <div className="title">What You'll Learn</div>
            <CardSlider data={courseData} cardImages={cardImages} />
        </div>
    );
};


